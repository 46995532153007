import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LifestylePage from '../../templates/lifestyle.js';
export const _frontmatter = {
  "title": "Lifestyle Careers"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Button = makeShortcode("Button");
const Section = makeShortcode("Section");
const Globe = makeShortcode("Globe");
const LifestyleCarousel = makeShortcode("LifestyleCarousel");
const BackgroundText = makeShortcode("BackgroundText");
const ContactForm = makeShortcode("ContactForm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = LifestylePage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Hero mdxType="Hero">
      <HeroImage mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAYsUzs10QD//xAAYEAEBAQEBAAAAAAAAAAAAAAACAQMAEv/aAAgBAQABBQI6nZLWGTVdMfHVpK9//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAdEAACAAcBAAAAAAAAAAAAAAAAAQIDEBESITFh/9oACAEBAAY/Am+bHLtY2zJRemT7T//EABkQAQEBAQEBAAAAAAAAAAAAAAERACExcf/aAAgBAQABPyGVKeDd2A+2Y5AfusqJ4nMoWrNu/9oADAMBAAIAAwAAABAEP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBUMtF//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EALpYv/EABwQAQACAgMBAAAAAAAAAAAAAAEAETFBIVGBkf/aAAgBAQABPxBaEUXL75Dsl0FzdvzEXIR4RzUY1xQ7WBoe4oHMZtdbn//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/229301af395efb6c182100d5d4cf86db/d3be9/lifestyle-training2.webp 480w", "/static/229301af395efb6c182100d5d4cf86db/e46b2/lifestyle-training2.webp 960w", "/static/229301af395efb6c182100d5d4cf86db/882b9/lifestyle-training2.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/229301af395efb6c182100d5d4cf86db/7cc5e/lifestyle-training2.jpg 480w", "/static/229301af395efb6c182100d5d4cf86db/6a068/lifestyle-training2.jpg 960w", "/static/229301af395efb6c182100d5d4cf86db/0f98f/lifestyle-training2.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/229301af395efb6c182100d5d4cf86db/0f98f/lifestyle-training2.jpg",
                "alt": "Solar Energy Lifestlye Careers",
                "title": "Solar Energy Lifestlye Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <Row mdxType="Row"><Column sm={60} mdxType="Column">
              <h1>{`live the solar sales lifestyle`}</h1>
              <Button as='a' href='#inquire' mdxType="Button">Start Your Career</Button>
            </Column></Row>
        </Container>
      </HeroContent></Hero>
    <Section theme='dark' style={{
      overflow: 'hidden'
    }} mdxType="Section"><Container style={{
        position: 'relative',
        zIndex: 1
      }} mdxType="Container">
        <Row mdxType="Row"><Column sm={50} mdxType="Column">
            <h2>{`See the World You're Saving Firsthand`}</h2>
            <p>{`Here at Solar Energy Partners we are passionate about saving the planet because there is so much of it we haven’t yet been able to see. Born out of that passion for good old Mother Earth, any of the SEP team has traveled extensively to see the wonders that we are saving with every installation of solar energy panels.`}</p>
            <p>{`Machu Picchu Peru, Chiangmai Thailand, 20+ Philippine islands, and multiple destinations in Mexico have been previous destinations and there will be more to come.`}</p>
            <p>{`If you want a life that takes you to the 7 wonders of the world, while you make impactful changes that preserve those same wonders for future generations, but also provides an income that can easily surpass 100K/year....YOU NEED TO APPLY NOW!!!`}</p>
          </Column></Row>
      </Container>
      <div style={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)'
      }}><Globe mdxType="Globe" /></div>
    </Section>
    <LifestyleCarousel mdxType="LifestyleCarousel" />
    <Section id='inquire' mdxType="Section">
      <Container style={{
        position: 'relative',
        marginTop: 100
      }} mdxType="Container">
        <h2 style={{
          position: 'relative',
          zIndex: 1
        }}>Get Started Today</h2>
        <BackgroundText content='Inquire' style={{
          top: -130
        }} mdxType="BackgroundText" />
        <ContactForm mdxType="ContactForm" />
      </Container>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      